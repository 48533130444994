import * as React from "react";
import { Switch, Route, Link } from "react-router-dom";
import "./App.css";
import BasicTabs from "./components/tabpanel"

function App() {
  return (
    <div className="App">
      <BasicTabs/>
    </div>
  );
}

export default App;