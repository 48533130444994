import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


class SquadSDK extends React.Component {

	render() {
	  	return (
    		<Box id="poems" sx={{ flexGrow: 1, bgcolor: 'background.paper' }}>
			    <Typography align="center" variant="h3" gutterBottom>Squad SDK</Typography>
			</Box>
	  );
	}
}

export default SquadSDK;