import * as React from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import './projectssidebar.css';

class ProjectsSideBar extends React.Component {

	constructor(props) {
	    super(props);
	    this.switchPage = this.switchPage.bind(this);
	  }


	switchPage(e, data) {
		this.props.selectPage(e.currentTarget.id);
	}

	render() {
	  return (
	    	<ProSidebar
	    	    collapsed={false}
      			toggled={false}
      			breakPoint="md">
			  <Menu>
			    <SubMenu title="AI/ML">
			      <MenuItem id={0} onClick={this.switchPage}>Neural Networks</MenuItem>
			    </SubMenu>
			    <SubMenu title="Game Development">
			      <MenuItem id={1} onClick={this.switchPage}>Squad SDK</MenuItem>
			    </SubMenu>
			    <SubMenu title="Design Principles">
			      <MenuItem id={2} onClick={this.switchPage}>Full Stack Development</MenuItem>
			      <MenuItem id={3} onClick={this.switchPage}>Event Driven Development</MenuItem>
			    </SubMenu>
			    <SubMenu title="Interview Questions">
			      <MenuItem id={4} onClick={this.switchPage}>FizzBuzz</MenuItem>
			      <MenuItem id={5} onClick={this.switchPage}>Two Sum</MenuItem>
			    </SubMenu>
			  </Menu>
			</ProSidebar>
	  );
	}
}


export default ProjectsSideBar;