import * as React from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import ProjectsSideBar from '../components/projectssidebar';
import NeuralNetworks from '../components/projects/ml/neuralnetworks';
import SquadSDK from '../components/projects/games/squadsdk';
import EventDrivenDev from '../components/projects/designs/eventdrivendevelopment';
import FullStackDev from '../components/projects/designs/fullstackdevelopment';
import FizzBuzz from '../components/projects/interviews/fizzbuzz';
import TwoSum from '../components/projects/interviews/twosum';

import '../components/content.css';
import 'react-pro-sidebar/dist/css/styles.css';
import './projects.css';

class Projects extends React.Component {

	constructor(props) {
	    super(props);
	    this.availablePages = [<NeuralNetworks/>,<SquadSDK/>,<FullStackDev/>,<EventDrivenDev/>,<FizzBuzz/>,<TwoSum/>];
	    this.state = {id : 0, pages : this.availablePages};
	    this.selectPage = this.selectPage.bind(this);
	}

	selectPage(id) {
		this.setState({id: id});
	}

	render() {
		const id = this.state.id;
		return (
			<div id="projects">
				<ProjectsSideBar selectPage={this.selectPage}/>
				<main >
					<div class="contents">
						{this.state.pages[id]}
					</div>
				</main>
			</div>
		);
	}
}


export default Projects;