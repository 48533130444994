import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import './home.css';

class Home extends React.Component {
	render() {
	  return (
	    <>
	    	
		<Container axWidth="sm" centered>
			<Box >
			    <Avatar id="profile" alt="Jason Vila" src="/profile.jpg" sx={{ width: 200, height: 200 }}/>
			    <Typography align="center" variant="subtitle1" gutterBottom></Typography>
				<Typography align="center" variant="h5" gutterBottom>
			        Jason Vila
			    </Typography>
			    <Typography align="center" variant="subtitle1" gutterBottom></Typography>
				<Typography align="center" variant="subtitle1" gutterBottom>Full Stack Software Engineer @ JPMorgan Chase & Co.</Typography>
				<Typography align="center" variant="subtitle1" gutterBottom>Risk and Structuring in the Equities Derivatives Business</Typography>
				<Container axWidth="xsm" centered>
					<Stack id="homelinks" direction="row" spacing={2} >
						<a href="https://github.com/jasonvila">
							<Avatar alt="Github" src="/github.png" sx={{ width: 75, height: 75 }}/>
						</a>
						<a href="https://www.linkedin.com/in/jasonvila?_l=en_US">
							<Avatar alt="Linkedin" src="/linkedin.png" sx={{ width: 75, height: 75 }}/>
						</a>
						<a href="mailto:jasonvila@live.com">
							<Avatar alt="Outlook" src="/outlook.png" sx={{ width: 75, height: 75 }}/>
						</a>
					</Stack>
				</Container>
			</Box>
		</Container>
	    </>
	  );
	}
}

export default Home;